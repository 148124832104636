<template>
  <div>
    <field-label v-bind="{ required }">
      <slot />
    </field-label>
    <input 
      name="new-password" 
      id="new-password" 
      autocomplete="new-password" 
      ref="googlePlaces" 
      class="input is-medium is-rounded"
      type="text" 
      placeholder="Type your address..." 
      :value="value"
      @input="emitText"
    >
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>
<script>
import GoogleAddress from './GoogleAddress'
const googlePlaces = new GoogleAddress()
import * as props from '@billow/vue-bulma/components/mixins/props'
import * as computed from '@billow/vue-bulma/components/mixins/computed'

export default {

  mixins: [
    props.error,
    computed.hasError,
  ],

  props: {
    value: String,
    required: Boolean,
  },

  data: () => ({
    center: {
      lat: 50.064192,
      lng: -130.605469,
    }
  }),

  created() {
    googlePlaces.init()
  },

  async mounted() {
    await this.getUserLocation()
    this.initGooglePlaces()
  },

  methods: {
    initGooglePlaces() {
      const defaultBounds = {
        north: this.center.lat + 0.1,
        south: this.center.lat - 0.1,
        east: this.center.lng + 0.1,
        west: this.center.lng - 0.1,
      }
      const input = this.$refs.googlePlaces

      const options = {
        bounds: defaultBounds,
        componentRestrictions: { country: 'za' },
        fields: ['address_components', 'geometry', 'utc_offset_minutes'],
        strictBounds: false,
        types: ['address'],
      }
      setTimeout(() => {
        this.googleMaps = new window.google.maps.places.Autocomplete(input, options)
        this.googleMaps.addListener('place_changed', () => {
          this.$emit('input', googlePlaces.parsePlace(this.googleMaps.getPlace()))
        })
      }, 1000)
    },
    async getUserLocation() {
      new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.center.lat = position.coords.latitude
            this.center.lng = position.coords.longitude
            resolve()
          }, () => {
            reject()
          })
        }
      })
    },
    emitText(event) {
      this.$emit('text-input', event.target.value)
    }
  }

}
</script>