export default class GoogleAddress
{

  apiKey = ''

  constructor() {
    this.apiKey = process.env.VUE_APP_GOOGLE_PLACES_KEY
    if (!this.apiKey) console.warn('Google Places Key Not Found.')
  }

  init() {
    if (!this.scriptIsInTheDom()) this.appendScript()
  }

  scriptIsInTheDom() {
    return document.getElementById('google-places') !== null
  }

  appendScript() {
    const script = document.createElement('script')
    script.async = true
    script.id = 'google-places'
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`
    document.body.appendChild(script)
  }

  parsePlace(place) {
    return {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      street: `${this.extractAddressComponent(place.address_components, 'street_number')} ${this.extractAddressComponent(place.address_components, 'route')}`,
      suburb: `${this.extractAddressComponent(place.address_components, 'sublocality_level_1')}`,
      city: `${this.extractAddressComponent(place.address_components, 'locality')}`,
      province: `${this.extractAddressComponent(place.address_components, 'administrative_area_level_1')}`,
      country: `${this.extractAddressComponent(place.address_components, 'country')}`,
      post_code: `${this.extractAddressComponent(place.address_components, 'postal_code')}`,
      utc_offset_minutes: place.utc_offset_minutes
    }
  }

  extractAddressComponent(addressComponents, search) {
    const components = addressComponents.filter(component => component.types.includes(search))
    if(components.length) {
      return components[0].long_name || ''
    }
    return ''
  }

}